/* 

.css-1ii34p9-MuiButtonBase-root-MuiIconButton-root-MuiPickersToolbar-penIconButton {
    display: none !important;
} */

.MuiPickersToolbar-penIconButton {
  display: none !important;
}
input::-ms-reveal,
input::-ms-clear {
  display: none;
}
.react-pdf__Page {
  margin-bottom: 50px;
}
.css-1y9k740-MuiModal-root-MuiPopover-root .MuiPaper-root {
  border-radius: 6px !important;
  width: 350px !important;
  box-shadow: 0px 5px 5px -3px rgba(145, 158, 171, 0.2),
    0px 8px 10px 1px rgba(145, 158, 171, 0.14),
    0px 3px 14px 2px rgba(145, 158, 171, 0.12) !important;
}
